"use client";
import PageLoading from "@components/Loading/PageLoading";
import { useState } from "react";
import { toast } from "react-toastify";

interface ActivateAccountModalProps {
  email: string;
  onClose: () => void;
}

const ActivateAccountModal: React.FC<ActivateAccountModalProps> = ({
  email,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);

  const handleActivate = async () => {
    setLoading(true);
    try {
      const res = await fetch("/api/auth/reactivateAccount", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      if (!res.ok) {
        throw new Error("Failed to reactivate account");
      }
      toast.success(
        "You successfully activated your account. Now you can log in!"
      );
      onClose();
    } catch (error) {
      console.error("Activation error:", error);
      toast.error("Activation failed. Please try again.");
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <PageLoading />}
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div
          className="absolute inset-0 bg-black opacity-50"
          onClick={onClose}
        />
        <div className="relative bg-white rounded p-6 z-10 w-11/12 max-w-md">
          <h2 className="text-xl font-semibold mb-4">Account Inactive</h2>
          <p className="mb-6">
            Your account is inactive. Press the button below to reactivate your
            account.
          </p>
          <button
            onClick={handleActivate}
            disabled={loading}
            className="bg-blue-500 text-white px-4 py-2 rounded w-full"
          >
            Reactivate Account
          </button>
        </div>
      </div>
    </>
  );
};

export default ActivateAccountModal;
