"use client";
import { signIn } from "next-auth/react";
import { toast } from "react-toastify";
import { useRouter, useSearchParams } from "next/navigation";
import Image from "next/image";
import LinkedinLogo from "@assets/icons/linkedin-logo.png";
import { useCookies } from "next-client-cookies";
import { useState } from "react";
import ActivateAccountModal from "@components/Reactive Account/ActivateAccountModal";

interface LinkedinLoginButtonProps {
  role?: string;
  setLoading?: (loading: boolean) => void;
}

const LinkedinLoginButton = ({
  setLoading,
  role,
}: LinkedinLoginButtonProps) => {
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [reactivationEmail, setReactivationEmail] = useState("");
  const router = useRouter();
  const cookies = useCookies();
  const searchParams = useSearchParams();
  const callbackUrl = searchParams.get("callbackUrl") || "/";

  const handleLinkedInLogin = async () => {
    setLoading?.(true);

    const res: any = await signIn("linkedin", {
      redirect: false,
      callbackUrl,
    });

    if (res?.error) {
      setLoading?.(false);
      if (res.error === "activate_user_account") {
        setReactivationEmail(res.email);
        setShowActivateModal(true);
        return;
      }
      toast.error(res.error);
      return;
    }

    const cookieData = cookies.get("OAuth-new-user");
    const newUser = cookieData ? JSON.parse(cookieData) : undefined;

    if (newUser && newUser.OAuth_new && newUser.role === "CANDIDATE") {
      cookies.remove("OAuth-new-user");
      router.replace("/create-profile/location");
    } else {
      router.replace(res?.url);
    }
  };

  return (
    <>
      <button
        type="button"
        className="text-[#FFFFFFCC] w-[240px] h-[48px] rounded-[60px] flex flex-row items-center gap-[10px] justify-center bg-[#FFFFFF08]"
        onClick={handleLinkedInLogin}
      >
        <Image width={30} height={30} src={LinkedinLogo} alt="Linkedin Logo" />
        <span>Continue with Linkedin</span>
      </button>
      {showActivateModal && (
        <ActivateAccountModal
          email={reactivationEmail}
          onClose={() => setShowActivateModal(false)}
        />
      )}
    </>
  );
};

export default LinkedinLoginButton;
