"use client";
import React, { useState } from "react";
import TextInput, { TextInputTypes } from "./TextInput";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { classNames } from "@lib/helpers";
import { useCookies } from "next-client-cookies";

const PasswordInput: React.FC<TextInputTypes> = ({
  className,
  error,
  ...props
}) => {
  const [inputType, setInputType] = useState("password");

  const cookies = useCookies();

  const isArabic = cookies.get("lang") === "ar";

  const toggleInputType = () => {
    setInputType((prevInputType) =>
      prevInputType === "password" ? "text" : "password"
    );
  };

  return (
    <div>
      <div className="w-full relative inline-flex">
        <TextInput
          {...props}
          className={classNames(className, isArabic ? "sm:pl-12" : "sm:pr-12")}
          type={inputType}
        />

        <button
          tabIndex={-1}
          type="button"
          className={classNames(
            props.inputSize === "small"
              ? `bottom-3 sm:bottom-3 ${isArabic ? "left-3" : "right-3"}`
              : `bottom-3.5 sm:bottom-[18px] ${
                  isArabic ? "left-4" : "right-4"
                }`,
            "absolute "
          )}
          onClick={toggleInputType}
        >
          {inputType === "password" ? (
            <EyeIcon className="w-6 text-gray-600" />
          ) : (
            <EyeSlashIcon className="w-6 text-gray-600" />
          )}
        </button>
      </div>
      <div
        className={classNames(
          "text-red-500 mt-1",
          props.inputSize === "small" ? "text-sm" : ""
        )}
      >
        {error}
      </div>
    </div>
  );
};

export default PasswordInput;
